.dz-input {
	position: relative;
	width: 100%;
	height: 44px;
	border-bottom: 1px solid #D9D9D9;
	transition: border-color .2s;
	>input {
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		font-size: 14px;
		line-height: 44px;
	}
	input::-webkit-input-placeholder {
		color: #999;
	}
	input::-moz-placeholder {
		color: #999;
	}
	input:-ms-input-placeholder {
		color: #999;
	}
	.dz-input-suffix {
		position: absolute;
		width: 14px;
		height: 12px;
		right: 8px;
		top: 0;
		bottom: 0;
		margin: auto;
		cursor: pointer;
		>img {
			width: 14px;
			height: 12px;
		}
	}
}
.dz-input-error {
	border-color: #FF003C;
}