.login {
	position: relative;
	width: 100%;
	height: 100%;
	background: url("../../../assets/sys/login/signin_bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	.login-box {
		overflow: hidden;
		position: absolute;
		width: 850px;
		height: 530px;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: #fff;
		.login-left {
			position: relative;
			width: 468px;
			height: 100%;
			float: left;
			background: url("../../../assets/sys/login/signin_left.png");
			background-size: cover;
			background-repeat: no-repeat;
			>div {
				position: absolute;
				left: 0;
				right: 0;
				text-align: center;
				>p{
					color: #fff;
					font-size: 16px;
					line-height: 20px;
				}
				>p:nth-child(2) {
					margin: 19px 0;
					font-size: 26px;
					font-family: arial,sans-serif;
				}
			}
			.login-left-top {
				top: 127px;
			}
			.login-left-bottom {
				top: 284px;
			}
		}
		.login-right {
			float: left;
			width: 382px;
			padding: 0 50px;
			.login-righ-title {
				margin-top: 64px;
				font-size: 20px;
				line-height: 20px;
			}
			.login-righ-subTitle {
				margin-top: 14px;
				font-size: 12px;
				line-height: 20px;
				color: #999;
			}
			.login-right-input {
				margin-top: 32px;
			}
			.login-right-password {
				margin-top: 19px;
			}
			.login-right-btn {
				height: 45px;
				margin-top: 30px;
				border-radius: 4px;
				line-height: 45px;
				text-align: center;
				color: #fff;
				cursor: pointer;
				user-select: none;
				background-color: #FF003C;
				transition: background-color .2s;
			}
			.login-right-btn:hover {
				background-color: #EB0032;
			}
			.login-right-btn:active {
				background-color: #D70032;
			}
			.login-right-error {
				margin-top: 13px;
				font-size: 12px;
				color: #FF003C;
				opacity: 1;
				transition: opacity .2s;
			}
			.login-right-error-hide {
				opacity: 0;
			}
			.login-right-bottom {
				position: absolute;
				bottom: 64px;
				font-size: 12px;
				line-height: 20px;
				font-weight: 300;
				color: #999;
				a {
					color: #4585DC;
					cursor: pointer;
					font-weight: 300;
					line-height: 20px;
				}
			}
		}
	}
}
