.dz-Spin {
	padding-top: 32px;
	.dz-Spin-icon {
		width: 32px;
		margin: 0 auto;
	}
	.dz-Spin-tip {
		margin-top: 8px;
		font-size: 16px;
		text-align: center;
		color: #0A88ED;
	}
}