.customerHome-title {
	overflow: hidden;
	height: 168px;
	padding-left: 380px;
	.customerHome-title-data {
		position: relative;
		float: left;
		width: 100%;
		height: 100%;
		padding-left: 20px;
		.customerHome-title-data-list {
			height: 100%;
			border-radius: 10px;
			background-color: #fff;
			.customerHome-title-data-item {
				position: relative;
				height: 100%;
				width: 33.33%;
				float: left;
				.customerHome-title-data-item-content {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					width: 210px;
					height: 70px;
					margin: auto;
					>img {
						float: left;
						width: 70px;
						height: 70px;
					}
					.customerHome-title-data-item-text {
						float: left;
						margin-left: 14px;
						>p:nth-child(1) {
							margin-top: 5px;
							margin-bottom: 12px;
							font-size: 30px;
							line-height: 30px;
							height: 30px;
						}
						>P:nth-child(2) {
							margin-bottom: 0;
							font-size: 16px;
							line-height: 16px;
							color: #666;
						}
					}
				}
			}
		}
	}
	.customerHome-title-company {
		position: relative;
		float: left;
		width: 380px;
		height: 100%;
		margin-left: -100%;
		right: 380px;
		padding: 30px 27px 30px 30px;
		border-radius: 10px;
		background-color: #fff;
		>img {
			float: left;
			width: 108px;
			height: 108px;
			margin-right: 16px;
			border-radius: 10px;
			border: 1px solid #F1F2F6;
			object-fit: cover;
		}
		>p {
			height: 108px;
			width: 199px;
			display: table-cell;
            vertical-align:middle;
			font-size: 20px;
		}
	}
}

.customerHome-charts {
	margin-top: 20px;
	border-radius: 10px;
	background-color: #fff;
	.customerHome-charts-title {
		height: 66px;
		padding: 16px 30px;
		>p {
			float: left;
			font-size: 20px;
			line-height: 34px;
		}
		.customerHome-charts-title-search {
			float: right;
		}
	}
	.customerHome-charts-content {
		height: 375px;
	}
}

.card {
	margin-top: 20px;
	padding: 0 30px 30px;
	border-radius: 10px;
	background-color: #fff;
	.card-title {
		height: 66px;
		margin-bottom: 0;
		line-height: 66px;
		font-size: 20px;
	}
	.customerHome-list {
		min-height: 174px;
		padding-top: 10px;
		overflow: hidden;
		.customerHome-item {
			margin-top: 20px;
			float: left;
			width: 50%;
			height: 154px;
			padding-right: 20px;
			//box-shadow: 0px 5px 20px 0px #2D2D2D;
			@media (min-width: 1024px) {
				width: 33.3%;
			} 
			@media (min-width: 1440px) {
				width: 25%;
			}
			.customerHome-item-content {
				position: relative;
				height: 100%;
				border-radius: 4px;
				border: 1px solid #ECECEC;
				cursor: pointer;
				>img {
					position: absolute;
					left: 20px;
					top: 20px;
					width: 90px;
					height: 90px;
					object-fit: cover;
				}
				.customerHome-item-textBox {
					position: absolute;
					left: 134px;
					right: 20px;
					top: 29px;
					bottom: 20px;
					.customerHome-item-title {
						min-height: 18px;
						margin-bottom: 0;
						font-size: 18px;
						line-height: 18px;
					}
					.customerHome-item-brand {
						position: relative;
						display: inline-block;
						padding-right: 25px;
						margin-top: 10px;
						>div {
							font-size: 14px;
							line-height: 20px;
							color: #666;
						}
						>img {
							position: absolute;
							right: 0;
							top: 0;
							margin-left: 5px;
							width: 20px;
							height: 20px;
							border-radius: 50%;
						}
					}
					.customerHome-item-count {
						margin-top: 18px;
						>div {
							float: left;
							width: 33.33%;
							.customerHome-item-count-content {
								width: 44px;
								>p:nth-child(1) {
									margin-bottom: 9px;
									font-size: 14px;
									text-align: center;
									line-height: 11px;
								}
								>p:nth-child(2) {
									margin-bottom: 0;
									font-size: 14px;
									color: #999;
								}
							}
						}
					}
				}
			}
			.customerHome-item-content:hover {
				box-shadow: 0px 5px 20px 0px #ddd;
				.customerHome-item-textBox {
					.customerHome-item-title {
						color: #FF003C;
					}
				}
			}
		}
	}
}

.title-data-item-line {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 1px;
	height: 80px;
	background-color: #ECECEC;
}

.pagination {
	margin-top: 30px;
	overflow: hidden;
}