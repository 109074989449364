.layout {
	height: 100%;
	overflow: hidden;
}
.layout-header {
	height: 70px;
	padding: 0 40px;
	background-color: #fff;
	.layout-header-logo {
		width: 140px;
		height: 30px;
		margin-top: 20px;
	}
	.layout-header-quit {
		margin-left: 19px;
		float: right;
		line-height: 70px;
		user-select: none;
		cursor: pointer;
	}
	.layout-header-quit:hover {
		color: #666;
	}
	.layout-header-quit:active {
		color: #666;
	}
	.layout-header-userName {
		margin-left: 16px;
		float: right;
		line-height: 70px;
	}
	.layout-header-avatar {
		float: right;
		height: 36px;
		width: 36px;
		margin-top: 17px;
	}
}
.layout-content {
	overflow-y: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 70px;
	bottom: 0;
	padding: 20px 24px 40px;
	background-color: #f0f2f5;
	@media (min-width: 1024px) {
		padding: 20px 48px 40px;
	} 
	@media (min-width: 1440px) {
		padding: 20px 116px 40px;
	}
}

