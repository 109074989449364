.dz-empty {
	padding-top: 73px;
	.dz-empty-img {
		display: block;
		margin: 0 auto;
		width: 374px;
		height: 280px;
	}
	.dz-empty-desc {
		text-align: center;
		font-size: 16px;
		color: #5B5B5B;
	}
}