.customerTask {
	position: relative;
	padding-top: 48px;
}

.customerTask-title {
	overflow: hidden;
	height: 168px;
	padding-left: 380px;
	.customerTask-title-data {
		position: relative;
		float: left;
		width: 100%;
		height: 100%;
		padding-left: 20px;
		.customerTask-title-data-list {
			height: 100%;
			border-radius: 10px;
			background-color: #fff;
			.customerTask-title-data-item {
				position: relative;
				height: 100%;
				width: 25%;
				float: left;
				.customerTask-title-data-item-content {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					width: 210px;
					height: 70px;
					margin: auto;
					>img {
						float: left;
						width: 70px;
						height: 70px;
					}
					.customerTask-title-data-item-text {
						//width: 76px;
						float: left;
						margin-left: 14px;
						>p:nth-child(1) {
							margin-top: 5px;
							margin-bottom: 12px;
							font-size: 30px;
							line-height: 30px;
							height: 30px;
						}
						>P:nth-child(2) {
							margin-bottom: 0;
							font-size: 16px;
							line-height: 16px;
							color: #666;
						}
						>p {
							@media (min-width: 1024px) {
								width: 76px;
							} 
							@media (min-width: 1234px) {
								width: 106px;
							} 
							@media (min-width: 1320px) {
								width: 126px;
							}
						}
					}
				}
			}
		}
	}
	.customerTask-title-company {
		position: relative;
		float: left;
		width: 380px;
		height: 100%;
		margin-left: -100%;
		right: 380px;
		padding: 30px 27px 30px 30px;
		border-radius: 10px;
		background-color: #fff;
		>img {
			float: left;
			width: 108px;
			height: 108px;
			margin-right: 16px;
			border-radius: 10px;
			border: 1px solid #F1F2F6;
			object-fit: cover;
		}
		>p {
			height: 108px;
			width: 199px;
			display: table-cell;
            vertical-align:middle;
			font-size: 20px;
			>span {
				display: block;
				margin-top: 16px;
				font-size: 16px;
				color: #666;
			}
		}
		
	}
}

.customerTask-charts {
	margin-top: 20px;
	border-radius: 10px;
	background-color: #fff;
	.customerTask-charts-title {
		height: 66px;
		padding: 16px 30px;
		>p {
			float: left;
			font-size: 20px;
			line-height: 34px;
		}
		.customerTask-charts-title-search {
			float: right;
		}
	}
	.customerTask-charts-content {
		height: 375px;
	}
}

.card {
	position: relative;
	margin-top: 20px;
	padding: 0 30px 30px;
	border-radius: 10px;
	background-color: #fff;
	.card-title {
		height: 66px;
		margin-bottom: 0;
		line-height: 66px;
		font-size: 20px;
	}
	.card-export {
		position: absolute;
		top: 18px;
		right: 30px;
		width: 82px;
		height: 30px;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
		cursor: pointer;
		user-select: none;
		>img {
			margin: -2px 7px 0 18px;
			width: 12px;
			height: 12px;
		}
		>span {
			font-size: 14px;
			line-height: 28px;
		}
	}
	.card-export:active {
		//border-color: rgb(215, 245, 250, .3);
		background-color: rgba(79, 83, 80, 0.05);
	}
	.card-srearch {
		position: absolute;
		top: 18px;
		right: 128px;
		height: 30px;
		width: 160px;
		.ant-select  {
			width: 100%;
		}
	}
	.customerWork-list {
		padding-top: 10px;
		overflow: hidden;
		.customerWork-item {
			position: relative;
			margin-top: 20px;
			float: left;
			width: 50%;
			height: 238px;
			min-width: 354px;
			//box-shadow: 0px 5px 20px 0px #2D2D2D;
			@media (min-width: 1024px) {
				width: 33.3%;
			} 
			@media (min-width: 1440px) {
				width: 25%;
			}
			.customerWork-item-content {
				position: relative;
				height: 100%;
				border-radius: 4px;
				border: 1px solid #ECECEC;
				overflow-y: hidden;
				>img {
					position: absolute;
					left: 20px;
					top: 20px;
					width: 90px;
					height: 160px;
					object-fit: cover;
				}
				.customerWork-item-textBox {
					position: absolute;
					left: 134px;
					right: 20px;
					top: 30px;
					bottom: 20px;
					.customerWork-item-title {
						min-height: 18px;
						margin-bottom: 15px;
						font-size: 18px;
						line-height: 18px;
					}
					.customerWork-item-center {
						>div {
							margin-top: 12px;
							line-height: 12px;
							font-size: 12px;
							color: #666666;
						}
					}
					
				}
				.customerWork-item-bottom {
					position: absolute;
					left: 20px;
					right: 20px;
					bottom: 20px;
					height: 24px;
					.customerWork-item-bottom-count {
						float: left;
						.customerWork-item-bottom-count-item {
							margin-right: 24px;
							float: left;
							>img {
								float: left;
								margin-top: 5px;
								width: 14px;
								height: 14px;
							}
							>span {
								margin-left: 5px;
								float: left;
								line-height: 24px;
							}
						}
					}
					.customerWork-item-bottom-goView {
						width: 70px;
						height: 24px;
						float: right;
						font-size: 12px;
						text-align: center;
						line-height: 24px;
						border-radius: 4px;
						color: #fff;
						background-color: #FF003C;
						cursor: pointer;
					}
					.customerWork-item-bottom-goView:hover {
						background-color: #EB0032;
					}
					.customerWork-item-bottom-goView:active {
						background-color: #D70032;
					}
				}

				.customerWork-item-abnormal {
					position: absolute;
					left: 0px;
					top: 0px;
					width: 60px;
					height: 32px;
					text-align: center;
					line-height: 32px;
					font-size: 12px;
					background-color: rgb(255, 153, 0, .8);
					color: #fff;
				}
			}
			// .customerWork-item-content:hover {
			// 	box-shadow: 0px 5px 20px 0px #ddd;
			// 	.customerWork-item-textBox {
			// 		.customerWork-item-title {
			// 			color: #FF003C;
			// 		}
			// 	}
			// }
		}
	}
}

.pagination {
	margin-top: 30px;
	overflow: hidden;
}