.dz-back {
	position: absolute;
	left: 0;
	top: -4px;
	width: 130px;
	height: 36px;
	margin-bottom: 16px;
	padding: 0 20px;
	border-radius: 4px 4px 4px 4px;
	background-color: #fff;
	cursor: pointer;
	>img {
		float: left;
		width: 6px;
		height: 10px;
		margin-top: 13px;
	}
	>span {
		float: right;
		line-height: 36px;
		font-size: 14px;
		color: #666;
	}
}